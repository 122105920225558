import { combineReducers } from "redux";

import { authReducer } from "../../pages/auth/reducer";
import { adminNewsReducer } from "../../pages/admin/News/reducer";
import { uiReducer } from "../../UISettings/reducer";


export const rootReducer = combineReducers({
    auth: authReducer,
    ui: uiReducer,
    news: adminNewsReducer
});

export type RootState = ReturnType<typeof rootReducer>;