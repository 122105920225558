import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import { CssBaseline } from '@mui/material';
import { ToastContainer } from 'react-toastify';

import { getLocalAccessToken } from "./http_comon"
import { useActions } from './hooks/useActions';
import { useTypedSelector } from './hooks/useTypedSelector';

import DefaultLayout from './containers/DefaultLayout';
import AdminLayout from './containers/AdminLayout';

import HomePage from './pages/default/HomePage';
import SignInPage from './pages/auth';

import NotFound from './pages/notfound';

import darkTheme from './UISettings/darkTheme';
import lightTheme from './UISettings/lightTheme';
import NewsTable from './pages/admin/News/Table';
import NewsCreate from './pages/admin/News/Create';

const App = () => {
  const { AuthUser } = useActions();
  const { isAuth, user } = useTypedSelector(store => store.auth);

  const { SetTheme } = useActions();
  const { isDarkTheme } = useTypedSelector((state) => state.ui);

  const darkThemeLS = localStorage.darkTheme == "true";

  useEffect(() => {
    if (darkThemeLS) {
      SetTheme(darkThemeLS);
    }
  }, []);

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark" />
        <Routes>

          <Route path="/" element={<DefaultLayout />}>
            <Route index element={<HomePage />} />
          </Route>

          {/* <Route element={<AuthLayout />}>
            <Route path="/auth/signin" element={<SignIn />} />
            <Route path="/auth/signup" element={<SignUp />} />
          </Route> */}

          {isAuth ?
            <>
              {user.role == "Admin" && <Route path="/admin/" element={<AdminLayout />}>
                <Route path="news" element={<NewsTable />} />
                <Route path="news/create" element={<NewsCreate />} />
              </Route>}
            </>
            : <>
              <Route path="/admin" element={<SignInPage />} />
            </>
          }
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
