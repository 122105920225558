import {
    NewsAction,
    NewsActionTypes,
    NewsState
} from "./types";

const initialState: NewsState = {
    count: 0,
    news: [],
}

export const adminNewsReducer = (state = initialState, action: NewsAction): NewsState => {
    switch (action.type) {
        case NewsActionTypes.SEARCH_NEWS:
            return {
                ...state,
                count: action.payload.count,
                news: action.payload.values,
            }
        default:
            return state;
    }
}