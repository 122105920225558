import { Dispatch } from "react"
import qs from "qs"

import http from "../../../http_comon"
import {
    INewsInfo,
    NewsAction,
    NewsActionTypes,
    ISearchNews,
    INewsImage,
    INewsRequest,
} from "./types"
import { ServerError } from "../../../store/types"

export const SearchNews = (page: number, rowsPerPage: number) => {
    return async (dispatch: Dispatch<NewsAction>) => {
        try {
            let response = await http.get<ISearchNews>(`api/News/Search`, {
                params: {
                    page: page,
                    rowsPerPage: rowsPerPage,
                },
                paramsSerializer: params => {
                    return qs.stringify({ ...params })
                }
            })

            dispatch({
                type: NewsActionTypes.SEARCH_NEWS,
                payload: response.data
            })

            return Promise.resolve();
        }
        catch (error) {
            return Promise.reject(error as ServerError)
        }
    }
}

export const CreateNewsImage = (base64: string) => {
    return async () => {
        try {
            let response = await http.post<INewsImage>(`api/NewsImage/Create`, base64)

            return Promise.resolve(response.data);
        }
        catch (error) {
            return Promise.reject(error as ServerError)
        }
    }
}

export const CreateNews = (values: INewsRequest) => {
    return async () => {
        try {
            let response = await http.post(`api/News/Create`, values)

            return Promise.resolve();
        }
        catch (error) {
            return Promise.reject(error as ServerError)
        }
    }
}

export const DeleteNews = (ids: readonly number[]) => {
    return async () => {
        try {
            await http.delete(`api/News/Delete`, {
                params: {
                    ids: ids,
                },
                paramsSerializer: params => {
                    return qs.stringify({ ...params })
                }
            })
            return Promise.resolve();
        }
        catch (error) {
            return Promise.reject(error as ServerError)
        }
    }
}