import { Container } from "@mui/material";
import { Outlet } from "react-router-dom";


const AdminLayout = () => {
    return (
        <Container component="main" sx={{ maxWidth: { xl: "xl", lg: "lg", md: "md" }, mb: 8, mt: "20px" }}>
            <Outlet />
        </Container>
    );
};

export default AdminLayout;