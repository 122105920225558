import { LoadingButton } from "@mui/lab";
import { Box, Grid, Typography } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { FC, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import AutocompleteComponent from "../../../../components/Autocomplete";
import { TextFieldFirstStyle } from "../../../../components/TextField/styled";
import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { toLowerFirstLetter } from "../../../../http_comon";
import { ServerError } from "../../../../store/types";
import { INewsRequest, INewsImage } from "../types";
import { CloudUpload } from "@mui/icons-material";

const NewsCreate = () => {

    const { CreateNewsImage, CreateNews } = useActions();

    const navigate = useNavigate();

    const [imagesLoading, setImagesLoading] = useState<number>(0);


    const item: INewsRequest = {
        title: "",
        urlSlug: "",
        images: [],
    }

    const formik = useFormik({
        initialValues: item,
        // validationSchema: validationFields,
        enableReinitialize: true,
        onSubmit: async (values, { setFieldError }) => {
            try {
                await CreateNews(values);
                navigate("/admin/news");
            }
            catch (ex) {
                const serverErrors = ex as ServerError;
                if (serverErrors.errors)
                    Object.entries(serverErrors.errors).forEach(([key, value]) => {
                        if (Array.isArray(value)) {
                            let message = "";
                            value.forEach((item) => {
                                message += `${item} `;
                            });
                            setFieldError(toLowerFirstLetter(key), message);
                        }
                    });
            }
        }
    });
    useEffect(() => {

    }, [imagesLoading, formik.values.images]);


    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (files) => {
            if (!files || files.length === 0) return;

            setImagesLoading(files.length)
            let tmp = formik.values.images.slice();
            files.forEach(element => {
                let reader = new FileReader();

                reader.readAsDataURL(element);
                reader.onload = async () => {
                    if (reader.result != null) {
                        let res = await CreateNewsImage(reader.result as string) as unknown as INewsImage;
                        tmp.push(res);
                    }
                };
            });

            setFieldValue("images", tmp)
            setImagesLoading(0)
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, setFieldValue, getFieldProps } = formik;

    return (
        <Box sx={{ flexGrow: 1, m: 1, mx: 3, }}>
            <Typography variant="h4" color="inherit" gutterBottom sx={{ my: "auto" }}>
                Create news
            </Typography>

            <Box sx={{ mt: 3 }} >
                <FormikProvider value={formik} >
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextFieldFirstStyle
                                    fullWidth
                                    variant="standard"
                                    autoComplete="title"
                                    type="text"
                                    label='Title'
                                    {...getFieldProps('title')}
                                    error={Boolean(touched.title && errors.title)}
                                    helperText={touched.title && errors.title}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldFirstStyle
                                    fullWidth
                                    variant="standard"
                                    autoComplete="urlSlug"
                                    type="text"
                                    label='Url slug'
                                    {...getFieldProps('urlSlug')}
                                    error={Boolean(touched.urlSlug && errors.urlSlug)}
                                    helperText={touched.urlSlug && errors.urlSlug}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Box
                                    sx={{
                                        height: "150px",
                                        borderRadius: "10px",
                                        cursor: "pointer",
                                        border: "1px solid #90caf9",
                                        display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"
                                    }}
                                >
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <input {...getInputProps()} />
                                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <CloudUpload sx={{ fontSize: 30 }} />
                                            <Typography variant="subtitle1" color="inherit" align="center">
                                                Move photo or search to upload
                                            </Typography>
                                        </Box>
                                    </div>
                                </Box>
                                <Box sx={{ display: "flex" }}>
                                    {imagesLoading > 0
                                        ? <div>Images are uploaded</div>
                                        : (formik.values.images?.length != 0 &&
                                            formik.values.images.map((row, index) => {
                                                return (
                                                    <Box
                                                        sx={{
                                                            width: "136px",
                                                            height: "136px",
                                                            marginTop: "10px",
                                                            marginRight: "10px",
                                                            borderRadius: "10px",
                                                            border: "1px solid #F45626",
                                                        }}
                                                    >
                                                        <img
                                                            key={`product_image_${index}`}
                                                            src={row.name}
                                                            alt="icon"
                                                            style={{
                                                                width: "134px",
                                                                height: "134px",
                                                                borderRadius: "10px",
                                                                objectFit: "scale-down"
                                                            }}
                                                        />
                                                    </Box>
                                                );
                                            }))
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
                            <LoadingButton
                                sx={{ paddingX: "35px", mt: "30px" }}
                                size="large"
                                type="submit"
                                variant="contained"
                                loading={isSubmitting}
                            >
                                Create
                            </LoadingButton>
                        </Grid>
                    </Form>
                </FormikProvider>
            </Box >
        </Box >
    )
}

export default NewsCreate