export enum NewsActionTypes {
    SEARCH_NEWS = "SEARCH_PRODUCTS"
}

export interface INewsImage {
    id: number,
    name: string,
    priority: number
}

export interface INewsInfo {
    id: number,
    title: string,
    image: string,
    urlSlug: string
}

export interface INewsRequest {
    title: string,
    urlSlug: string,
    images: Array<INewsImage>,
}

export interface ISearchNews {
    count: number,
    values: Array<INewsInfo>
}

export interface NewsState {
    count: number,
    news: Array<INewsInfo>,
}


export interface SearchProductsAction {
    type: NewsActionTypes.SEARCH_NEWS,
    payload: ISearchNews
}


export type NewsAction = SearchProductsAction;