import {
    AppBar,
    Box,
    Container,
    useTheme,
    Typography,
    Grid
} from "@mui/material";
import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";

import LinkRouter from "../../components/LinkRouter";

import { logo } from "../../assets/logos";
import { instagram_outlined, telegram_outlined } from "../../assets/icons";

import { DonationButtonStyle } from "./styled";


interface IAppBarItem {
    lable: string,
    path: string,
}

const Header = () => {
    const { palette } = useTheme();
    const navigate = useNavigate();

    const [pages, setPages] = useState<Array<IAppBarItem>>([
        { lable: 'Головна', path: '/' },
        { lable: 'Про нас', path: '/' },
        { lable: 'Анонс', path: '/' },
        { lable: 'Проєкти', path: '/' },
        { lable: 'Як відбувається захід?', path: '/' },
        { lable: 'Контакти', path: '/' },
    ]);

    return (
        <AppBar component="header" elevation={0} position="static" sx={{ height: "64px", borderBottom: (theme) => `1px solid ${theme.palette.primary.light}` }}>
            <Container sx={{ height: "100%", maxWidth: { xl: "xl", lg: "lg", md: "md" } }}>
                <Grid container spacing={0} sx={{ height: "100%", display: "flex", alignItems: "center", px: "65px" }}>
                    <Grid item xs={2}>
                        <LinkRouter underline="none" color="unset" to="/" sx={{ height: "35px" }}>
                            <img
                                src={logo}
                                alt="logo"
                            />
                        </LinkRouter>
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: "space-between" }}>
                        {pages.map((page, index) => (
                            <LinkRouter key={index} underline="none" color="unset" to={page.path}>
                                <Typography fontSize="14px" lineHeight="22px" fontFamily="Fixel Display, Mulish" sx={{ padding: "12px 16px" }}>
                                    {page.lable}
                                </Typography>
                            </LinkRouter>
                        ))}
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={2} sx={{ display: 'flex', justifyContent: "space-between" }}>
                        <DonationButtonStyle>
                            ДОНАТ
                        </DonationButtonStyle>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img
                                src={instagram_outlined}
                                alt="instagram"
                                style={{ marginRight: "12px", cursor: "pointer" }}
                            />
                            <img
                                src={telegram_outlined}
                                alt="telegram"
                                style={{ cursor: "pointer" }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </AppBar >
    );
};

export default Header;