import {
    Box,
    Typography,
    IconButton,
    Grid,
    ListItem,
    ListItemIcon,
    Paper,
    useTheme,
    ImageList,
    ImageListItem,
} from '@mui/material'
import {
    ArrowBackIosNewOutlined,
    ArrowForwardIosOutlined,
    PhotoOutlined,
} from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';

import LinkRouter from '../../../components/LinkRouter';

import { home_page_main, partner_1, partner_2, partner_3, reporting_1, reporting_2, reporting_3, reporting_4, reporting_5, team_member_1, team_member_2, team_member_3 } from '../../../assets/backgrounds';
import { arrow_circle, audit_outlined, read_outlined, team_outlined } from '../../../assets/icons';

import { MoreInfoButtonStyle } from './styled';


const HomePage = () => {
    const { palette } = useTheme();
    const navigate = useNavigate();

    const [images, setPages] = useState<Array<string>>([
        reporting_1,
        reporting_2,
        reporting_3,
        reporting_4,
        reporting_5,
    ]);

    const [partners, setPartners] = useState<Array<string>>([
        partner_1,
        partner_2,
        partner_3,
        partner_1,
        partner_2,
        partner_3,
        partner_1,
        partner_2,
        partner_3,
        partner_1,
        partner_2,
        partner_3,
    ]);

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        try {
        } catch (ex) {
        }
    };

    return (
        <Grid container spacing={0} sx={{ pt: "70px", px: "65px", pb: "77px" }}>
            <Grid item xs={8}>
                <Typography variant="h1" fontFamily="Druk Wide, Mulish" fontWeight="bold" fontSize="50px" lineHeight="62px">
                    Плекаємо українську культуру, будуємо демократичне суспільство
                </Typography>
                <Typography variant="h3" fontFamily="Fixel Display, Mulish" color="text.secondary" sx={{ pt: "16px" }}>
                    Створюємо проєкти, що об'єднують молодь навколо української культури та формують свідоме демократичне суспільство. Віримо, що культура — це сила, яка надихає та змінює покоління.
                </Typography>
                <MoreInfoButtonStyle sx={{ mt: "50px" }}>
                    Наші заходи тут
                </MoreInfoButtonStyle>
            </Grid>
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "end" }}>
                <img
                    src={home_page_main}
                    alt="home_page_main"
                />
            </Grid>
            {/* Про нас */}
            <Grid item xs={12} sx={{ mt: "150px" }}>
                <Typography variant="h2" fontFamily="Druk Wide, Mulish" fontWeight="bold" fontSize="36px" lineHeight="38px">
                    Про нас
                </Typography>
                <Typography variant="h4" fontFamily="Fixel Display, Mulish" color="text.secondary" sx={{ pt: "16px" }}>
                    AboutUkraine — це благодійна організація, створена для підтримки української культури, допомоги тим, хто постраждав від війни, та зміцнення суспільного духу через об’єднання однодумців. Ми віримо, що, допомагаючи один одному та зберігаючи культурну спадщину, ми можемо створити краще майбутнє для України.
                </Typography>
                <Typography variant="h4" fontFamily="Fixel Display, Mulish" color="text.secondary" sx={{ pt: "32px" }}>
                    Ми прагнемо підтримувати та розвивати українську культуру, надаючи ресурси для відновлення, розвитку та творчості, зокрема для тих, хто потерпає від сучасних викликів і труднощів. Наші заходи, проєкти та ініціативи спрямовані на об'єднання громади, створення можливостей для самореалізації та підтримку тих, хто цього найбільше потребує.
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "end", mt: "16px" }}>
                    <MoreInfoButtonStyle>
                        Дізнатися більше
                    </MoreInfoButtonStyle>
                </Box>
            </Grid>
            {/* Наша місія/візія */}
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", mt: "50px" }}>
                <Box
                    sx={{
                        width: "640px",
                        height: "300px",
                        background: (theme) => theme.palette.primary.dark,
                        border: (theme) => `1px solid ${theme.palette.text.primary}`,
                        borderRadius: "8px",
                    }}
                >
                    <Typography variant="h2" fontFamily="Druk Wide, Mulish" fontWeight="bold" fontSize="36px" lineHeight="38px" sx={{ pt: "21px", px: "22px" }}>
                        Наша місія
                    </Typography>
                    <Typography variant="h4" fontFamily="Fixel Display, Mulish" sx={{ pt: "14px", px: "22px" }}>
                        Ми прагнемо популяризувати українську культуру серед молоді та її представлення як джерела натхнення й інструменту для суспільного розвитку. Ми віримо, що культура здатна об'єднувати, формуючи громадянську свідомість і сприяючи побудові відкритого, демократичного суспільства.
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "end", alignItems: "end", pt: "17px", pr: "28px" }}>
                        <img
                            src={arrow_circle}
                            alt="arrow_circle"
                            style={{ cursor: "pointer" }}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: "640px",
                        height: "300px",
                        background: (theme) => theme.palette.primary.dark,
                        border: (theme) => `1px solid ${theme.palette.text.primary}`,
                        borderRadius: "8px",
                    }}
                >
                    <Typography variant="h2" fontFamily="Druk Wide, Mulish" fontWeight="bold" fontSize="36px" lineHeight="38px" sx={{ pt: "21px", px: "22px" }}>
                        Наша візія
                    </Typography>
                    <Typography variant="h4" fontFamily="Fixel Display, Mulish" sx={{ pt: "14px", px: "22px" }}>
                        Свідоме українське суспільство, де кожен глибоко розуміє багатство та унікальність української культури й історії, цінує її як невід’ємну частину ідентичності, зберігає і передає ці надбання наступним поколінням, підтримуючи тяглість традицій та духовних цінностей.
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "end", alignItems: "end", pt: "45px", pr: "28px" }}>
                        <img
                            src={arrow_circle}
                            alt="arrow_circle"
                            style={{ cursor: "pointer" }}
                        />
                    </Box>
                </Box>
            </Grid>
            {/* Наша діяльність */}
            <Grid item xs={8} sx={{ mt: "128px" }}>
                <Typography variant="h2" fontFamily="Druk Wide, Mulish" fontWeight="bold" fontSize="36px" lineHeight="38px">
                    Наша діяльність
                </Typography>
                <Typography variant="h5" fontFamily="Fixel Display, Mulish" color="text.secondary" sx={{ pt: "16px" }}>
                    Ми проводимо навчальні сесії та тренінги, спрямовані на підвищення обізнаності про українську<br />культуру, історію, а також на розвиток навичок, необхідних для підтримки та просування українських<br />інтересів у світі. Наші тренінги допомагають учасникам глибше зрозуміти<br />українські реалії та набути знань.
                </Typography>
            </Grid>
            <Grid item xs={4} sx={{ mt: "128px" }}>
                <Box
                    sx={{
                        width: "394px",
                        height: "137px",
                        background: (theme) => theme.palette.text.secondary,
                        border: (theme) => `1px solid ${theme.palette.text.primary}`,
                        borderRadius: "8px",
                        display: "flex"
                    }}
                >
                    <Box sx={{ pt: "7px", pl: "11px" }}>
                        <img
                            src={audit_outlined}
                            alt="audit_outlined"
                        />
                    </Box>
                    <Typography
                        variant="h3"
                        fontFamily="Fixel Display, Mulish"
                        fontWeight={600}
                        fontSize="24px"
                        lineHeight="24px"
                        textTransform="uppercase"
                        sx={{
                            color: (theme) => theme.palette.background.default,
                            alignContent: "center",
                            p: "7px 15px 7px 20px",
                        }}
                    >
                        Збереження та популяризація української культурної спадщини
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                    sx={{
                        width: "394px",
                        height: "137px",
                        background: (theme) => theme.palette.text.secondary,
                        border: (theme) => `1px solid ${theme.palette.text.primary}`,
                        borderRadius: "8px",
                        display: "flex"
                    }}
                >
                    <Box sx={{ pt: "7px", pl: "11px" }}>
                        <img
                            src={read_outlined}
                            alt="read_outlined"
                        />
                    </Box>
                    <Typography
                        variant="h3"
                        fontFamily="Fixel Display, Mulish"
                        fontWeight={600}
                        fontSize="22px"
                        lineHeight="24px"
                        textTransform="uppercase"
                        sx={{
                            color: (theme) => theme.palette.background.default,
                            alignContent: "center",
                            p: "7px 15px 7px 20px",
                        }}
                    >
                        Неформальна освіта для молоді
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box
                    sx={{
                        width: "394px",
                        height: "137px",
                        background: (theme) => theme.palette.text.secondary,
                        border: (theme) => `1px solid ${theme.palette.text.primary}`,
                        borderRadius: "8px",
                        display: "flex"
                    }}
                >
                    <Box sx={{ pt: "7px", pl: "11px" }}>
                        <img
                            src={team_outlined}
                            alt="team_outlined"
                        />
                    </Box>
                    <Typography
                        variant="h3"
                        fontFamily="Fixel Display, Mulish"
                        fontWeight={600}
                        fontSize="22px"
                        lineHeight="24px"
                        textTransform="uppercase"
                        sx={{
                            color: (theme) => theme.palette.background.default,
                            alignContent: "center",
                            p: "7px 0px 7px 20px",
                        }}
                    >
                        Мистецька діяльність задля розвитку демократичних цінностей українського суспільства
                    </Typography>
                </Box>
            </Grid>
            {/* Наша звітність */}
            <Grid item xs={12} sx={{ mt: "73px" }}>
                <Typography variant="h2" fontFamily="Druk Wide, Mulish" fontWeight="bold" fontSize="36px" lineHeight="38px">
                    Наша звітність
                </Typography>
                <Box sx={{ height: "650px", mt: "52px" }}>
                    <ImageList variant="masonry" cols={3} gap={13} sx={{ width: "fit-content" }}>
                        {images.map((item, index) => (
                            <ImageListItem key={`reporting_${index}`} sx={{ width: "fit-content" }}>
                                <img
                                    src={item}
                                    alt={`reporting_${index}`}
                                    loading="lazy"
                                    style={{ width: "fit-content" }}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", mt: "30px" }}>
                    <MoreInfoButtonStyle>
                        Дізнатися більше
                    </MoreInfoButtonStyle>
                </Box>
            </Grid>
            {/* Наша команда */}
            <Grid item xs={12} sx={{ mt: "76px" }}>
                <Typography variant="h2" fontFamily="Druk Wide, Mulish" fontWeight="bold" fontSize="36px" lineHeight="38px">
                    Наша команда
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", pt: "42px" }}>
                    <img
                        src={team_member_1}
                        alt="team_member_1"
                    />
                    <Box>
                        <img
                            src={team_member_2}
                            alt="team_member_2"
                        />
                        <LinkRouter underline="always" color="inherit" to="/">
                            <Typography variant="h4" fontFamily="Fixel Display" textAlign="center" sx={{ mt: "42px" }}>
                                Більше...
                            </Typography>
                        </LinkRouter>
                    </Box>
                    <img
                        src={team_member_3}
                        alt="team_member_3"
                    />
                </Box>
            </Grid>
            {/* Наші партнери */}
            <Grid item xs={12} sx={{ mt: "72px" }}>
                <Typography variant="h2" fontFamily="Druk Wide, Mulish" fontWeight="bold" fontSize="36px" lineHeight="38px">
                    Наші партнери
                </Typography>
                <Box sx={{ pt: "22px" }}>
                    <Swiper
                        modules={[Navigation]}
                        navigation={true}
                        slidesPerView={7}
                        slidesPerGroup={1}
                        spaceBetween={24}
                    >
                        {partners.map((item, index) => {
                            return (
                                <SwiperSlide key={`partner_${index}`}>
                                    <img
                                        src={item}
                                        alt={`partner_${index}`}
                                    />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </Box>
            </Grid>
        </Grid>
    )
}

export default HomePage