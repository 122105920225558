import * as AuthActionCreators from "../../pages/auth/actions"

import * as AdminNewsActionCreators from "../../pages/admin/News/actions"
import * as UIActionCreators from "../../UISettings/actions"

const actions = {
    ...AuthActionCreators,
    ...AdminNewsActionCreators,
    ...UIActionCreators
}
export default actions;